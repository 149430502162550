<template>
    <v-container fluid>
        <v-row :align="'center'" :justify="'center'" class="mb-5">
            <v-spacer v-if="!$vuetify.breakpoint.xs"/>
            <v-col :align="'center'" :justify="'center'">
                <h1>{{ $t('tax.createTaxReportTitle') }}</h1>
            </v-col>
            <v-spacer  v-if="!$vuetify.breakpoint.xs" />
        </v-row>
        <v-stepper v-model="e1" tile>
            <v-stepper-header>
                <v-stepper-step :complete="e1 > 1" step="1" color="accent">
                    {{ $t('tax.createTaxReportStep1Title') }}
                </v-stepper-step>
                <v-divider></v-divider>
                <!--
                <v-stepper-step :complete="e1 > 2" step="2" color="accent">
                    {{ $t('tax.createTaxReportStep2Title') }}
                </v-stepper-step>

                <v-divider></v-divider>
                -->
                <v-stepper-step :complete="e1 > 2" step="2" color="accent">
                    {{ $t('tax.createTaxReportStep3Title') }}
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step color="accent" step="3">
                    {{ $t('tax.createTaxReportStep4Title') }}
                </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
                <v-stepper-content step="1">
                    <v-row>
                        <v-col>
                            <v-row class="mb-3" no-gutters>
                                <v-col>
                                    {{ $t('tax.createTaxReportAddressDescription') }}
                                </v-col>
                            </v-row>
                            <v-row v-for="address in getPersonalAddresses" :key="address.address.address" no-gutters
                                class="my-1">
                                <v-col cols="auto">
                                    <blockchain-type-component
                                        :blockchainType="getBlockchainTypeFromAddress(address.address.address)"
                                        :showText="false"></blockchain-type-component>
                                </v-col>
                                <v-col class="mx-3">
                                    <b> {{ address.name }}</b> ({{ $t('label.updated') }} <i>{{ address.address.updated |
                                        LastDateFormatter }}</i>)
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col :align="'end'">
                            <router-button :text="$t('connection.addConnectionButton')"
                                redirect="/add-connection"></router-button>
                        </v-col>
                    </v-row>

                    <v-row no-gutters class="mt-3">
                        <v-spacer></v-spacer>
                        <v-col cols="auto">
                            <v-btn text tile @click="$router.push('/tax-center')">{{ $t('label.back') }}</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn color="accent" tile @click="e1 = 2">{{ $t('label.continue') }}</v-btn>
                        </v-col>
                    </v-row>
                </v-stepper-content>
                <v-stepper-content step="2">

                    <v-row class="mt-3 mb-3" no-gutters>
                        <v-col>
                            {{ $t('tax.downloadTaxReportDialogDescription') }}
                        </v-col>
                    </v-row>
                    <v-row class="mx-1">
                        <v-col>
                            <country-select :selectedCountry="country" :countries="defaultCountries"
                                @updateCountry="updateCountry">
                            </country-select>
                        </v-col>
                        <v-col>
                            <currency-select :selectedCurrency="taxCurrency" :currencies="defaultTaxCurrencies"
                                @updateCurrency="updateTaxCurrency" :disabled="isChoiceDisabled">
                            </currency-select>
                        </v-col>
                        <v-col>
                            <v-select v-model="costBasisMethod" :items="defaultCostBasisMethods" persistent-hint
                                :hint="$t('tax.costBasisMethod')" :label="$t('tax.costBasisMethod')"
                                :disabled="isChoiceDisabled" solo>
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row class="mx-1">
                        <v-col cols="auto" class="mr-3">
                            <v-switch v-model="emoney" inset
                                 :label="$t('tax.emoney')"
                                color="accent">
                                <template slot="append">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on">
                                                mdi-help-circle-outline
                                            </v-icon>
                                        </template>
                                        <span>
                                            {{ $t('tax.emoneySwitchTooltip') }}
                                        </span>
                                    </v-tooltip>
                                </template>
                            </v-switch>
                        </v-col>
                        <v-col cols="auto">
                            <v-switch v-model="threshold" inset
                                 :label="$t('tax.threshold')"
                                color="accent">
                                <!-- Show tooltip when mouseover on appen icon -->
                                <template slot="append">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on">
                                                mdi-help-circle-outline
                                            </v-icon>
                                        </template>
                                        <span>
                                            {{ $t('tax.thresholdSwitchTooltip') }}
                                        </span>
                                    </v-tooltip>
                                </template>
                            </v-switch>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-3">
                        <v-spacer></v-spacer>
                        <v-col cols="auto">
                            <v-btn text tile @click="e1 = 1">{{ $t('label.back') }}</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn tile color="accent" :disabled="isButtonDisabled" @click="goToRecap">{{
                                $t('label.continue') }}</v-btn>
                        </v-col>
                    </v-row>
                </v-stepper-content>
                <!--
                <v-stepper-content step="2">
                    <v-row class="mb-10">
                        <tax-center-history-table></tax-center-history-table>
                    </v-row>
                    <v-row>
                        <v-spacer></v-spacer>
                        <v-col cols="auto">
                            <v-btn text @click="e1 = 1">{{ $t('label.back') }}</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn color="accent" @click="e1 = 3">{{ $t('label.continue') }}</v-btn>
                        </v-col>
                    </v-row>
                </v-stepper-content>
                -->
                <v-stepper-content step="3">
                    <v-row  class="mt-3" no-gutters>
                        {{ $t('tax.createTaxReportLastStepDescription') }}
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-row class="mb-3" no-gutters>
                                <h2>{{ $t('tax.createTaxReportConnections') }}</h2>
                            </v-row>
                            <v-row v-for="address in getPersonalAddresses" :key="address.address.address" no-gutters
                                class="my-1">
                                <v-col cols="auto">
                                    <blockchain-type-component
                                        :blockchainType="getBlockchainTypeFromAddress(address.address.address)"
                                        :showText="false"></blockchain-type-component>
                                </v-col>
                                <v-col class="mx-3">
                                    <b> {{ address.name }}</b> ({{ $t('label.updated') }} <i>{{ address.address.updated |
                                        LastDateFormatter }}</i>)
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col>
                            <v-row class="mb-3" no-gutters>
                                <h2>{{ $t('tax.createTaxReportSettings') }}</h2>
                            </v-row>
                            <v-row no-gutters class="my-3">
                                <b class="mr-3">{{ $t('label.countryType') }}: </b>
                                <v-avatar v-if="country" size="20">
                                    <v-img :src="require(`../../assets/country/${country}.svg`)"></v-img>
                                </v-avatar>
                                <span class="mx-1">
                                    {{ country | CountryTypeFormatter }}
                                </span>
                            </v-row>
                            <v-row no-gutters class="mb-3">
                                <b class="mr-3">{{ $t('label.currency') }}: </b>
                                <v-avatar  v-if="taxCurrency" size="20">
                                    <v-img :src="require(`../../assets/currency/${taxCurrency}.svg`)"></v-img>
                                </v-avatar>
                                <span class="mx-1">
                                    {{ taxCurrency }}
                                </span>
                            </v-row>
                            <v-row no-gutters class="mb-3">
                                <b class="mr-3">{{ $t('tax.costBasisMethod') }}:</b> {{ costBasisMethod |
                                    CostBasisMethodFormatter }}
                            </v-row>
                            <v-row no-gutters class="mb-3">
                                <b class="mr-3">{{ $t('tax.emoney') }}:</b> {{ emoney |
                                    YesNoFormatter }}
                            </v-row>
                            <v-row no-gutters class="mb-3">
                                <b class="mr-3">{{ $t('tax.threshold') }}:</b> {{ threshold |
                                    YesNoFormatter }}
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-3">
                        <v-spacer></v-spacer>
                        <v-col cols="auto">
                            <v-btn text tile @click="e1 = 2">{{ $t('label.back') }}</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn color="accent" tile @click="createTaxReportRequest">{{ $t('tax.createTaxReportButton')
                            }}</v-btn>
                        </v-col>
                    </v-row>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </v-container>
</template>

<script>
// import TaxCenterHistoryTable from '../../components/table/TaxCenterHistoryTable.vue'
import CountrySelect from '../../components/select/CountrySelect.vue';
import CurrencySelect from '../../components/select/CurrencySelect.vue';
import BlockchainTypeComponent from '../../components/misc/BlockchainTypeComponent.vue';
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex';
import RouterButton from '../../components/button/RouterButton.vue';
export default {
    name: 'user-create-tax-report-page',
    components: {
        CurrencySelect, CountrySelect,
        // TaxCenterHistoryTable,
        BlockchainTypeComponent,
        RouterButton
    },
    data() {
        return {
            e1: 1,
            loading: false,
            taxCurrency: null,
            defaultTaxCurrencies: ["USD", "EUR"],
            defaultCountries: [{ value: "ITALY", text: "ITALY", disabled: false }, { value: "REST_OF_THE_WORLD", text: "REST_OF_THE_WORLD", disabled: false }],
            defaultCostBasisMethods: [
                {
                    text: "LIFO",
                    value: "LIFO",
                },
                {
                    text: "FIFO",
                    value: "FIFO",
                },
                {
                    text: this.$t("tax.costBasisMethodAverageCost"),
                    value: "AVERAGE_COST",
                }
            ],
        }
    },
    computed: {
        ...mapFields("tax", ["currency", "country", "costBasisMethod", "emoney", "threshold"]),
        ...mapFields("userAddresses", ["addresses"]),
        ...mapFields("loggedUser", ["loggedUser"]),
        isChoiceDisabled() {
            if (this.country === "ITALY") {
                return true
            }
            return false
        },
        isButtonDisabled() {
            return this.country == null || this.taxCurrency == null || this.costBasisMethod == null || this.emoney == null || this.threshold == null
        },
        getPersonalAddresses() {
            return this.addresses.filter(x => x.userAddressType === 'PERSONAL').sort((a, b) => a.name.localeCompare(b.name));
        }
    },
    methods: {
        ...mapActions("alert", ["createAlert"]),
        ...mapActions("tax", ["requestTaxReport"]),
        ...mapActions("loggedUser", ["updateUserData"]),
        updateTaxCurrency(taxCurrency) {
            this.taxCurrency = taxCurrency
        },
        updateCountry(country) {
            this.country = country
            if (this.country === "ITALY") {
                this.taxCurrency = "EUR"
                this.costBasisMethod = "LIFO"
            }
        },
        updateUserSettings() {
            this.updateUserData(this.loggedUser)
        },
        getBlockchainTypeFromAddress(address) {
            if (address.startsWith("0x")) {
                return "ETHEREUM"
            }
            if (address.startsWith("bc1") || address.startsWith("1") || address.startsWith("3") || address.startsWith("xpub")) {
                return "BITCOIN"
            }
            return address.split("_")[0].toUpperCase()
        },
        goToRecap() {
            this.e1 = 3;
            if (this.country != this.loggedUser.settings.country || this.taxCurrency != this.loggedUser.settings.taxCurrency || this.costBasisMethod != this.loggedUser.settings.costBasisMethod || this.emoney != this.loggedUser.settings.emoney || this.threshold != this.loggedUser.settings.threshold) {
                this.loggedUser.settings.country = this.country
                this.loggedUser.settings.taxCurrency = this.taxCurrency
                this.loggedUser.settings.costBasisMethod = this.costBasisMethod
                this.loggedUser.settings.emoney = this.emoney
                this.loggedUser.settings.threshold = this.threshold
                this.updateUserData(this.loggedUser)
            }
        },
        createTaxReportRequest() {
            this.loading = true
            const payload = {
                currencyType: this.taxCurrency,
                countryType: this.country,
                costBasisMethodType: this.costBasisMethod,
                emoney: this.emoney == null ? false : this.emoney,
                threshold: this.threshold == null ? false : this.threshold
            }
            this.requestTaxReport(payload).then(() => {
                this.createAlert({ message: this.$t('message.createTaxReportSuccess'), type: "success" })
                this.$router.push('/tax-center')
                this.$emit("taxReportCreated", true);
            }).catch((err) => {
                if (err.response.data && err.response.data.message) {
                    if (err.response.data.message == 'TAX_REPORT_ALREADY_EXISTS') {
                        this.createAlert({ message: this.$t('message.taxReportAlreadyExistsError'), type: "error" })
                    } else if (err.response.data.message == 'TAX_REPORT_IS_CREATING') {
                        this.createAlert({ message: this.$t('message.taxReportIsCreatingError'), type: "error" })
                    } else if (err.response.data.message == 'WRONG_PRICING_PLAN') {
                        this.createAlert({ message: this.$t('message.taxReportWrongPricingError'), type: "error" })
                    } else {
                        this.createAlert({ message: this.$t('message.genericError'), type: "error" })
                    }
                } else {
                    this.createAlert({ message: this.$t('message.genericError'), type: "error" })
                }
            }).finally(() => {
                this.loading = false
            });
        },
    },
    created() {
        if (this.country == null) {
            this.country = this.loggedUser.settings.country
        }
        if (this.taxCurrency == null) {
            this.taxCurrency = this.loggedUser.settings.taxCurrency
        }
        if (this.costBasisMethod == null) {
            this.costBasisMethod = this.loggedUser.settings.costBasisMethod
        }
        if (this.emoney == null) {
            this.emoney = this.loggedUser.settings.emoney == null ? false : this.loggedUser.settings.emoney 
        }
        if (this.threshold == null) {
            this.threshold = this.loggedUser.settings.threshold == null ? false : this.loggedUser.settings.emoney 
        }
    }
}
</script>

<style></style>